<template>
  <div >
    <div class="mapLeft" :style="{width: fullState? '0' : '300px',overflow:'auto'}">
      <div class="Putaway"  @click="Putaway()" :style="{right: fullState? '3px' : '290px'}">
        <img src="@/assets/btn.png" :style="{transform:fullState? 'rotate(180deg)': 'rotate(0deg)'}">
      </div>
     <el-form ref="searchForm" class="searchForm"  :model="searchForm" label-width="80px">
          <!-- <el-form-item label="巡检片区" prop="networkId">
            <el-select v-model="searchForm.networkId" size="mini" style="width: 100%;" clearable @change="setNet">
              <el-option
                  v-for="item in networkStatus"
                  :key="item.value"
                  :label="item.network_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
       <el-form-item label="设备状态" prop="equipmentStatus">
         <el-select multiple v-model="equipmentStatuses"  style="width: 100%" clearable  @change="reloadList">
           <el-option
               v-for="item in UserStatus"
               :key="item.id"
               :label="item.name"
               :value="item.dictCode">
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="设备类型" prop="equipmentType">
         <!-- <el-select multiple v-model="equipmentTypees"  style="width: 100%" clearable  @change="reloadList">
           <el-option
               v-for="item in DeviceTypeArray"
               :key="item.code"
               :label="item.name"
               :value="item.code">
           </el-option>
         </el-select> -->
         <el-cascader
                  size="mini"
                  multiple
                  style="width: 100%"
                  :options="DeviceTypeArray"
                  v-model="equipmentTypees"
                  :props="{  label:'name' , value:'id',multiple: true  }"
                  separator='>'
                  clearable
                  collapse-tags
                  @change="reloadList">
                </el-cascader>
       </el-form-item>
          <el-form-item label="" prop="searchText" label-width="0" >
            <div style="display: flex;justify-content:space-between;align-items:center">
            <el-input v-model="searchForm.searchText" placeholder="请输入设备名称" style="width:206px" clearable></el-input>
            <el-button
              size="small"
              type="primary"
              class="searchBtn"
              @click="reloadList"
              >查询</el-button
            >
            </div>
          </el-form-item>         
          <el-form-item label-width="0px" class="searchBt">
            
            <el-button
              size="small"
              type="primary"
              class="addBtn"
              @click="addPoint"
              style="flex:1"
              v-if="$anthButtons.getButtonAuth('addEquipment')"  
              >新增巡检设备</el-button
            >
            <el-button @click="replacement" 
            style="flex:1"
            v-if="$anthButtons.getButtonAuth('resetEquipment')"   size="small" >重置</el-button>
            <el-button
              size="small"
              type="primary"
              class="addBtn"
              style="flex:1"
              v-if="$anthButtons.getButtonAuth('downloadEquipment')"
              @click="printQrcode"
              >二维码</el-button
            >
          </el-form-item>
        </el-form>
        <div class="topbt">
            <el-radio-group v-model="radio1" @change="system">
              <el-radio-button label="1">本系统设备</el-radio-button>
              <el-radio-button label="2">GIS设备</el-radio-button>
            </el-radio-group>
          </div>
          <div style="padding-left:20px;font-size:14px;height:30px;line-height:30px;">总数：{{total}}</div>
        <div>
            <el-table
              ref="tableList"
              :data="tableData"
              height='476px'
              style="width: 100%"
              border
              highlight-current-row
              :header-cell-style="{fontSize: '14px',color:'#0C235B',height:'36px',background: '#EBEDF5'}"
              :stripe="true"
            > 

              <el-table-column prop="equipmentName" label="设备名称" align="center" >
                <template slot-scope="{ row }">
                  <p
                   v-if="row.state"
                   style="position: absolute;
                    width: 6px;
                    height: 100%;
                    background: red;
                    top: 0;
                    left: 0;"></p>
                  <span @click="gotoEdit(row)" class="blue">{{row.equipmentName || '--'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="equipmentTypeName" label="设备类型"  align="center" width="85px">
              </el-table-column>
              <!-- <el-table-column prop="equipmentStatus" label="状态"  align="center" width="60px" >
                <template slot-scope="{ row }">
                  {{startName(row.equipmentStatus,'UserStatus')}}
                </template>
              </el-table-column> -->
              <el-table-column prop="checkingPoint" label="巡检点" align="center"  width="70px">
                <template slot-scope="{ row }">
                  <el-switch v-model="row.checkingPoint" @change="(val)=>{return checkPoint(val, row)}" :active-value="1" :inactive-value="0"></el-switch>              
                </template>
              </el-table-column>
              <!-- <el-table-column prop="isPunch" label="打卡点" align="center" width="70px">
                <template slot-scope="{ row }">
                  <el-switch :disabled="row.checkingPoint == 0" v-model="row.isPunch"  @change="(val)=>{return clockPoint(val, row)}"  :active-value="0" :inactive-value="1"></el-switch>
                </template>
              </el-table-column> -->
              
            </el-table>
          <div class="pageBox">
            <el-pagination
                small
                :current-page="pageParams.current"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
                layout=" sizes,prev, pager, next"
                :total="total"
                :page-count="5"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>

        </div>
    </div>
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
      <subMap ref="subMap" :modeal="'1'" :layer-name="layerName" :layers-e="layers" :layer-code="layerCode" @setLocation="setLocation"/>
      <equipmentForm ref="formBox" :showForm="showForm" v-if="showForm" @save="save" @closePDraw="closePDraw" :map="$refs.subMap" :addForm='addForm'/>
    </div>
     <AsetQrcodes ref="qrcode"/>
  </div>
</template>

<script>
import AsetQrcodes from "@/components/AsetQrcodes";
import subMap from '@/views/mapTool/subMapEquipment'
import { getEquipmentPage,setcheckpoint,equipmentsDetail } from '@/apis/commonType'
import equipmentForm from '@/views/linegasMap/commonForm/equipmentForm'
import {getNetwork} from "../../RequestPort/maintenance/task";
import {SelectDic} from "../../RequestPort/equipment/line";
import { dicByType } from "../../RequestPort/maintenance";
import {serverMap} from "@/views/Gis/utils/dict"
// import {getDict} from "../../apis/commonType";
import Cookies from "js-cookie";
export default {
  name: 'GisMap',
  components: {subMap ,equipmentForm,AsetQrcodes},

  data() {
    return {
      name: '管线',
      layers:"'patrol_equipments'",
      // ,'patrol_networks_jihe'
      layerName: 'patrol_equipments',
      layerCode: '',
      pageParams: {
        current: 1,
        size: 100
      },
      pagerCount:3,
      // 设备类型下拉
      DeviceTypeArray:[],
      networkStatus:[],
      UserStatus:[],
      total: 0,
      radio1:1,
      searchForm:{},
      tableData:[],
      addForm:{
        equipmentTime:'',
        equipmentHome:'',
        equipmentName:'',
        equipmentType:'',
        equipmentSpecifications:'',
        equipmentStatus:'',
        equipmentUseTime:'',
        equipmentDepartmentId:'',
        equipmentProducers:'',
        equipmentStreet:'',
        eventTypeIdList :[],
        value:[],
        source:1,
        networkId:'',
        lineId:'',
        },
      showForm:false,
      //使用状态
      equipmentStatuses:[],
      // 设备类型
      equipmentTypees:[],
      // 管线管理弹窗的展示隐藏
      fullState:false,
    }
  },
  computed: {},
  destroyed () {
    this.$eventBus.$off('vectoradd') 
   },
  mounted() {
    this.$eventBus.$on('vectoradd', data => { 
      this.showForm = false
      this.addForm={
        equipmentTime:'',
        equipmentHome:'',
        equipmentName:'',
        equipmentType:'',
        equipmentSpecifications:'',
        equipmentStatus:'',
        equipmentUseTime:'',
        equipmentDepartmentId:'',
        equipmentProducers:'',
        equipmentStreet:'',
        eventTypeIdList :[],
        value:[],
        source:1,
        networkId:'',
        lineId:'',
        }
    })
   this.getList()
    getNetwork().then(res=>{
      this.networkStatus = res.data
    })
    dicByType({parentCode:'sblx',level:3}).then(res => {
        if(res.code == 200) {
            this.DeviceTypeArray = res.data
        } else {
            this.$message.error(res.msg)
        }
    }) 
     this.$eventBus.$emit('pageStart', 'equipment' )
    // 管线状态
      this.UserStatus = serverMap[1007]
    // getDict({parentCode:20000}).then((e)=>{
    //   this.DeviceTypeArray = e.data
    // })
    // this.$store.commit("layersChange", 'noQuery');
    this.reloadList(1)
  },
  methods: {
    startName(val,name){
      for(let i=0;i<this[name].length;i++){
        if(this[name][i].dictCode == val) {
          return this[name][i].name
        } 
      }
    },
    /**
     * 选择那个系统的管线
     */
    system(val){
      this.pageParams.current = 1
       this.getList()
    },
    //批量打印二维码
    printQrcode(){
      //调用子组件打印
      let arr = []
      this.tableData.forEach((el,index) => {
        arr.push({id:el.id+',' +el.tableName,name:el.equipmentName != 'GIS设备' ? el.equipmentName : 'GIS设备'+index})
      })
      this.$refs.qrcode.printQrCode(arr)
    },
    replacement(){
      this.$eventBus.$emit("RemoveArea");
      this.pageParams= {
        current: 1,
        size: 100,
      }
      this.equipmentStatuses=[]
      this.searchForm = {}
      this.equipmentTypees =[]
      // 更新管理列表
      this.getList()
      // 关闭弹窗
      this.closePDraw()
      this.reloadList()
    },
    /**
     * 搜索
     */
    reloadList(type){
      if(type != 1){
      this.$eventBus.$emit("RemoveArea");
      }
      let src = ''
      let typesrc = ''
      let typesrces = ''
      let condition =""
      let sql = ""
      let typesrcNumber = []
      // 使用状态
      if(this.equipmentStatuses.length > 0) {
        let index = 0
         this.equipmentStatuses.forEach(el => {
           index+=1
           if(this.equipmentStatuses.length <= index){
             src +="'"+el+"'"
           } else {
             src += "'"+el+"',"
           }
        } )
        condition = 'equipment_status in('+src+')'
        sql =  condition
      }
      // 设备类型
      if(this.equipmentTypees.length > 0) {
        let index = 0
         this.equipmentTypees.forEach(item => {
           index+=1
           if(this.equipmentTypees.length <= index){
             typesrc += "'"+item[1]+"'"
           } else {
             typesrc += "'"+item[1]+"'"+","
           }
           typesrcNumber.push(item[1])
        } )
        typesrces = 'equipment_type in ('+ typesrc +')'
        sql = typesrces
      }
      // 设备名称
      if(this.searchForm.searchText) {
        let en=encodeURIComponent(this.searchForm.searchText)//'%251%23%25'
        sql = " (equipment_name like '%25"+ en+ "%25')"
      }
   
      // 状态和设备类型
      if(this.equipmentStatuses.length > 0 && this.equipmentTypees.length > 0) {
        sql =  condition +' and '+ typesrces 
      }
      // 状态和设备名称
      if(this.equipmentStatuses.length > 0 && this.searchForm.searchText) {
        let en=encodeURIComponent(this.searchForm.searchText)
        sql =  condition +" and (equipment_name like '%25"+ en +"%25')"
      }
      // 设备名称和设备类型
      if(this.equipmentTypees.length > 0 && this.searchForm.searchText) {
        let en=encodeURIComponent(this.searchForm.searchText)
        sql =  typesrces +" and (equipment_name like '%25"+ en +"%25')"
      } 
      // 设备名称和设备类型和状态
      if(this.equipmentStatuses.length > 0 &&this.equipmentTypees.length > 0 && this.searchForm.searchText){
        let en=encodeURIComponent(this.searchForm.searchText)
        sql = condition +' and '+ typesrces +" and (equipment_name like '%25"+ en +"%25')"
      }
      // 啥条件没有
      if(this.equipmentStatuses.length == 0 &&this.equipmentTypees.length == 0 && !this.searchForm.searchText){
        sql = "1=1"
      }else{
        sql = '('+sql + " and type='point') or type='line' or type='network' or type='envent' or type='user'"
      }
      console.log(sql)
      if  (type!=1){
        //  sql=encodeURIComponent(sql)
        //  console.log(sql)
      this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking',sql});
      }
      this.searchForm.equipmentStatus = this.equipmentStatuses+''
      
      this.searchForm.equipmentType = typesrcNumber+''
      this.pageParams.current = 1
       this.getList()
    },
    setNet(){   
      let list=this.networkStatus
      list.forEach(item => {
        if(this.searchForm.networkId==item.id){
          this.$eventBus.$emit('showPolygon',item.location)
        }
      });
      this.pageParams.current = 1
      this.getList()
    },
     getCenterPoint(path) {
      var x = 0.0
      var y = 0.0
      for (var i = 0; i < path.length; i++) {
        x = x + parseFloat(path[i][0])
        y = y + parseFloat(path[i][1])
      }
      x = x / path.length
      y = y / path.length

      return [x, y]
    },
    /**
     * 查看详情（点击设备找到对应点和打开编辑页面）
     */
    gotoEdit(row){
      this.tableData.forEach((el) => {
        if (el.id == row.id) {
          el.state = true;
        } else {
          el.state = false;
        }
      });
      this.$eventBus.$emit('typeEdit',true)
      this.$forceUpdate()
      // let loctions = this.$refs.subMap.formatLocation(row.locations)
      this.$refs.subMap.loctiones(row.locations)
      // this.closePDraw()
      this.$eventBus.$emit('xfSwitch',true)
      let lnglat = new AMap.LngLat(row.locations[0], row.locations[1])
      if(this.radio1 == 1) {
        row.table_namees = 'patrol_equipments'
      } else {
        row.table_namees = 'equipments'
      }
      let data={
        lnglat,
        data:row,
        type:'view_all_point',
        start:'linqing:view_all_point'
      }
      
      this.$eventBus.$emit('patrolLinesfun', data )
    },
    /**
     * 点击收回（右侧菜单展示）
     */
    Putaway() {
      this.fullState = !this.fullState
    },
    editFun(item){
    //  this.$refs.subMap.setCenter([item.locations[0],item.locations[1]])
    //  this.$refs.subMap.SetMarkerHighLight([item.locations[0],item.locations[1]])
      let p = {
        equipmentId: item.id // data.item_key
      }
      equipmentsDetail(p).then(res => {
        let data={
            data:res.data.records[0],
            type:'view_all_point'
          }
          this.$eventBus.$emit('vectorEdit', data )
      })
    },
    /**
     * 新建设备弹窗
     */
    addPoint(){
      this.$eventBus.$emit('typeEdit',true)
      this.$eventBus.$emit("RemoveArea");
      this.$message.success('请先在地图上点选位置，然后填写右侧表单')
       this.$refs.subMap.getLocation()
       this.$eventBus.$emit('xfSwitch',true)
      //  if(Cookies.get("adsorbSwitch") != 'false'){
      //  this.$eventBus.$emit("addEquipment", 'patrol_lines');
      //   this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking',sql:''});
      //  }
    },
  
      setLocation(point){
      this.addForm.locations=[point.lng, point.lat]
      this.showForm=true
      let lnglat = [point.lng, point.lat]
      var geocoder = new AMap.Geocoder()
      let that=this
      geocoder.getAddress(lnglat, function(status, result) {
        if (result.info === 'OK') {
          that.addForm.equipmentHome=result.regeocode.formattedAddress
        }      
      })
    //  this.addForm.networkId=1
    },
    /**
     * 取消按钮
     */
    closePDraw(){
      if(this.$refs.formBox) {
        this.$refs.formBox.resetFields()
      }
       this.$eventBus.$emit('closeEdit')
      this.$refs.subMap.clearLocation()
      this.showForm=false
       this.$eventBus.$emit('xfSwitch')
       this.addForm={
        equipmentTime:'',
        equipmentHome:'',
        equipmentName:'',
        equipmentType:'',
        equipmentSpecifications:'',
        equipmentStatus:'',
        equipmentUseTime:'',
        equipmentDepartmentId:'',
        equipmentProducers:'',
        equipmentStreet:'',
        eventTypeIdList :[],
        value:[],
        source:1,
        networkId:'',
        lineId:'',
        }
    },
    // setformatter(row) {     
    //   let arr = ['停用','在用','废弃']     
    //   return arr[row.equipmentStatus]
    // },
    checkPoint(val,row){
      let parames={
        ids:[row.id],
        setType:val,
        tableName:row.tableName
      }
       setcheckpoint(parames).then(res => {
         if(res.code==200){
           this.$message.success('操作成功')
         }
         this.getList()
      }).catch(err => {
        this.getList()
      })
    },
    /**
     * 打卡点
     */
    // clockPoint(val,row){
    //   let parames={
    //     ids:[row.id],
    //     isPunch:val
    //   }
    //   if(row.checkingPoint == 1){
    //     setcheckpoint(parames).then(res => {
    //       if(res.code==200){
    //         this.$message.success('操作成功')
    //       }
    //       this.getList()
    //     })
    //   }

    // },
    save(){
         this.getList()
         this.closePDraw()
         this.$eventBus.$emit('reloadLayer')
    },
    /**
     * 查询设备列表
     */
    getList() {
      let p = {
        ...this.pageParams,
        ...this.searchForm
      }
      if(this.tableData.length == 1 && (this.pageParams.current != 1)) {
        p.current -= 1 
      }
      if(this.radio1 == 1){
        p.tableName = 'patrol_equipments'
      }else {
        p.tableName = 'gis'
      }
      getEquipmentPage(p).then(res => {
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>

.blue{color:#409EFF;cursor: pointer;}
// 右边总盒子
.mapLeft {
  height: calc(100vh - 122px);
  width:300px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
  float:right;
.searchBtn{
  width: 65px;
height: 34px;
    
}
// label内容
/deep/.el-form-item__label{
  color: #0c235b;
}
// 搜索input
/deep/.el-input__inner{
  height: 36px;
}
// 表格行高
/deep/.el-table__row{
  line-height: 42px;
}
// 开关
/deep/.el-switch__core{
  width: 33px!important;
  height: 16px;
}
// 开关按钮
/deep/.el-switch__core:after{
    width: 12px;
    height: 12px;
}
.topbt{text-align: right;;margin-top:10px;}
.el-radio-group{
  display: flex;
.el-radio-button{
  flex: 1;
  /deep/.el-radio-button__inner{
    width: 100%;
  }
}
}
// 开关按钮
/deep/.el-switch.is-checked .el-switch__core::after{
  margin-left: -12px!important;
}
// 分页
.pageBox{
  width:100%;
  text-align: center;
  margin-top:25px;
  .el-pagination{
    margin: 15px 0 40px;
  }
  /deep/.el-input--suffix{
    width: 53px;
  }
  /deep/.el-input__inner{
      width: 66px;
      padding-right: 15px;
      height: 24px;
    }
    /deep/.el-pager li.active{
        min-width: 10px;
        padding: 0;
     }
     /deep/.btn-prev{
      padding: 0;
     }
     /deep/.btn-next{
      padding: 0;
     }
}
}
.mapRight{
  width: auto;
  margin-left:0px;position: relative;overflow: hidden;
}
.searchForm{margin:15px 15px 0 5px;
/deep/.el-form-item {
    margin-bottom: 12px;
}
}
.searchBt{
  justify-content: space-between;
    display: flex;
}
.formBox{position:absolute;right:0;top:100px;background: #fff;width: 300px;padding-top:10px;
.t{padding-left:10px;font-size: 16px;}
::v-deep{.el-divider--horizontal{margin-top:10px;margin-bottom:10px}
.el-date-editor.el-input, .el-date-editor.el-input__inner{width: 100%;}
}
}
// 收回按钮
.Putaway {
  background: #737E95;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  // right: 5.25rem;
  // right: 340px;
  color: var(--title-text-color);
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.56rem;
}
/deep/.el-table__body tr.current-row>td.el-table__cell{
  background-color: #dfeeff !important;

  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */

}
</style>
